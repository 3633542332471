import  { useEffect, useState } from 'react'
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard'
import UsersAccount from './pages/UsersAccount'
import UsersAccessManagement from './pages/UsersAccessManagement'
import UsersFundManagement from './pages/UsersFundManagement'
import ApproveWithdrawal from './pages/ApproveWithdrawal'
import WithdrawalHistory from './pages/WithdrawalHistory'
import WithdrawalMethod from './pages/WithdrawalMethod'
import ManageActiveInvestments from './pages/ManageActiveInvestments'
import ApprovePayments from './pages/ApprovePayments'
import PaymentHistory from './pages/PaymentHistory'
import CreateTransactions from './pages/CreateTransactions'
import ReferralCommisions from './pages/ReferralCommisions'
import SendEmail from './pages/SendEmail'
import { selectUserInfo } from './slices/authSlice';
import { useSelector } from 'react-redux'
import Messages from './pages/Messages';
import Loans from './pages/Loans';
import Activities from './pages/Activities';



function App() {

    const [isLoading, setIsLoading] = useState(true);
    const userInfo = useSelector(selectUserInfo)

    useEffect(() => {
     
      setTimeout(() => {
        setIsLoading(false);
      }, 3000); 
    }, []);

 

   const ProtectedRoute =({children})=>{
            
    if(!userInfo?.user){
     return <Navigate to='/' />
    }
    return children
 }

  return (
    <div style={{ overflow:'hidden',position:'inherit',height:'100%'}}>
     <BrowserRouter>
     {/* {isLoading ? <Loader /> : null} */}
   
     <Routes>
     <Route path='/'  element={<Home/>} />
     <Route path='/dashboard'  element={<ProtectedRoute>
                                          <Dashboard/>
                                          </ProtectedRoute>} />
                                          
     <Route path='/usersAccount'  element={ <ProtectedRoute>
                                                <UsersAccount />
                                                </ProtectedRoute>} />
     <Route path='/usersAccessManagement'  element={ <ProtectedRoute>
                                                        <UsersAccessManagement />
                                                        </ProtectedRoute>
                                                        } />
     <Route path='/usersFundManagement'  element={<ProtectedRoute>
                                                    <UsersFundManagement />
                                                    </ProtectedRoute>} />
      <Route path='/approveWithdrawal'  element={<ProtectedRoute>
                                                  <ApproveWithdrawal />
                                                  </ProtectedRoute>} />
      <Route path='/withdrawalHistory'  element={
                                                  <ProtectedRoute>
                                                    <WithdrawalHistory />
                                                  </ProtectedRoute>} />
      <Route path='/withdrawalMethod'  element={<ProtectedRoute>
                                                  <WithdrawalMethod />
                                                  </ProtectedRoute>} />
       <Route path='/manageActiveInvestments'  element={ <ProtectedRoute>
                                                            <ManageActiveInvestments  />
                                                            </ProtectedRoute>} />
       <Route path='/approvePayment'  element={<ProtectedRoute>
                                                  <ApprovePayments />
                                                </ProtectedRoute>
                                                } />
     <Route path='/loans'  element={<ProtectedRoute>
                                                  <Loans />
                                                </ProtectedRoute>
                                                } />
      <Route path='/paymentHistory'  element={<ProtectedRoute>
                                                <PaymentHistory />
                                                </ProtectedRoute>} />
 <Route path='/createTransactions'  element={<ProtectedRoute>
                                                <CreateTransactions />
                                              </ProtectedRoute>
                                              } />
   <Route path='/messages'  element={<ProtectedRoute>
                                                <Messages />
                                              </ProtectedRoute>
                                              } />
 <Route path='/referralCommissions'  element={<ProtectedRoute>
                                                <ReferralCommisions />
                                                </ProtectedRoute>} />
      <Route path='/sendEmails'  element={<ProtectedRoute>
                                                <SendEmail />
                                                </ProtectedRoute>} />
      <Route path='/activities'  element={<ProtectedRoute>
                                                <Activities />
                                                </ProtectedRoute>} />
                                        
     </Routes>
    {/* <Footer /> */}
     </BrowserRouter>
      
    </div>
  )
}

export default App

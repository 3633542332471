import AdminNavbar from '../components/AdminNavbar'
import AdminSidebar from '../components/AdminSidebar'
import ApprovePaymentColumn from '../components/ApprovePaymentColumn'
import React from 'react'

const page = () => {
  return (
    <div>
      <AdminNavbar />
      <AdminSidebar />
      <ApprovePaymentColumn />
    </div>
  )
}

export default page

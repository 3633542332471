import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styles from './usersAccountColumn.module.css'

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'fullName', headerName: 'Fullname', width: 120 },
    { field: 'email', headerName: 'Email', width: 100 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 100 },
    { field: 'referredBy', headerName: 'Referred By', width: 100 },
    { field: 'wallet', headerName: 'Wallet', width: 100 },
      {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div style={{borderRadius:'10px'}} className='bg-green-600 p-2 text-white'>
            {params.row.status}
          </div>
        );
      },
    },
  ];
  const rows = [
    { id: 1, fullName: 'Mike Ejiagha', email: 'mm@m.co', phoneNumber:'902833445', referredBy: 'Jane Doe', referralID: '87', wallet: '$7876',  status:'verified'},
    { id: 2, fullName: 'Joe Ejiagha ', email: 'hghg@hj.co', phoneNumber:'90283344500', referredBy: 'Jane Doe', referralID: '87', wallet: '$7876', status:'verified' },
    { id: 3, fullName: 'Lord Bedtner ', email: 'hggnj@hhhj.co', phoneNumber:'9028334450', referredBy: 'Jane Doe', referralID: '87', wallet: '$7876', status:'verified' },
    { id: 4, fullName: 'Donal Trump ', email: 'jhjh@jhhhj.co', phoneNumber:'9028334450', referredBy: 'Jane Doe', referralID: '87', wallet: '$7876',  status:'verified' },
    // Add more data rows as needed
  ];
  // type MyDataGridProps = {
  //   pageSize: number;
  //   rowsPerPageOptions: number[];
  // };


const usersFundManagementColumn = () => {

    const actionColumn = [{
        field: "action",
         headerName: "Action",
          width: 500,
           renderCell:(params)=>{
            // console.log(params.row)
        return (
            <div className="flex gap-1 pr-2">
  
               {/* <Link to={`/paymentRecords/${params.row.id}`}   state={{ weekProcessed: weekProcessed, week1Filtered:week1Filtered, weekData:weekData }}   style={{textDecoration: 'none'}}> */}
                
               <div style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-sky-200 hover:bg-sky-600">Update Withdrawal List</div>
               <div style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-red-200 hover:bg-red-600">Block Withdrawal</div>
               <div style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-sky-200 hover:bg-sky-600">Assign Referal Upline</div>
               {/* </Link> */}
              
            </div>
        )
    }}];

  return (
    <div className={styles.datatable}>
    <h1 className="m-4 text-2xl">
       USERS
    </h1>
    <DataGrid
      className={styles.datagrid}
      rows={rows} columns={columns.concat(actionColumn)}
    //   columns={requestColumns.concat(actionColumn)}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      GridLinesVisibility="Vertical"
    />
    {/* {loading ? <h1>Loading...</h1> : null} */}
  </div>
  )
}

export default usersFundManagementColumn


import React, { useState } from 'react'
import { BiSolidDashboard } from 'react-icons/bi'
import { BiSolidBank } from 'react-icons/bi'
import { BiSolidNetworkChart } from 'react-icons/bi'
import { BiSolidRightArrow } from 'react-icons/bi'
import { BiSolidDownArrow } from 'react-icons/bi'
import { BsFingerprint } from 'react-icons/bs'
import { BsFillBoxFill } from 'react-icons/bs'
import { BiMoneyWithdraw } from 'react-icons/bi'
import { SiWebmoney } from 'react-icons/si'
import { AiFillSetting } from 'react-icons/ai'
import { BsFillSendFill } from 'react-icons/bs'
import { CgProfile } from 'react-icons/cg'
import robot from '../assets/robotLogo.png'
import logo from '../assets/crownstreetlogo.png'
import { Link } from 'react-router-dom'

const AdminSidebar = () => {
  // const [openAuth, setOpenAuth] = useState(false)
  // const [openReferral, setOpenReferral] = useState(false)
  const [openWithdrawal, setOpenWithdrawal] = useState(false)
  const [openInvestments, setOpenInvestments] = useState(false)
  const [openUsers, setOpenUsers] = useState(false)
  const [openPayments, setOpenPayments] = useState(false)
  return (
    <div style={{zIndex:55}} className="drawer">
    <input id="my-drawer" type="checkbox" className="drawer-toggle" />
    <div className="drawer-content">
      {/* Page content here */}
     
      <label style={{ border:'none', color:'rgb(58, 135, 224)', position:'absolute', top:-60,left:150, height:40, width:40, display:'flex', alignItems:'center',justifyContent:'center'}} htmlFor="my-drawer" className="btn btn-primary drawer-button bg-green-600" >
        {/* <FiMenu /> */}
        <img
         src={robot}
         style={{ width:'100%', height:30, objectFit:'cover'}}
        alt='welcome image'
          />
        </label>
    </div> 
    <div className="drawer-side">
      <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
      <ul className="menu p-4 w-60 min-h-full bg-base-200 text-base-content">
        {/* Sidebar content here */}
        <li>
        <img
            src={logo}
            style={{objectFit:'contain', width:200, height:100}}
           alt='welcome image'
  
          />
        </li>
        <li className='flex gap-4 mt-10' ><Link className='flex gap-3' to='/dashboard'> <BiSolidDashboard style={{color:'rgb(58, 135, 224)',fontSize:28}} /> Dashboard  </Link> </li>
        <li className='flex gap-4' ><Link className='flex gap-3' to='/activities'> <SiWebmoney style={{color:'rgb(58, 135, 224)',fontSize:28}} /> Activities  </Link> </li>


        <li className='flex gap-4 ' >
          <div onClick={()=>setOpenUsers(!openUsers)} className='flex justify-between items-center'>

          <div className='flex gap-3 items-center'  > 
        <CgProfile  style={{color:'rgb(58, 135, 224)', fontSize:28}} /> All Users </div>
        {!openUsers ? <BiSolidRightArrow /> : <BiSolidDownArrow />}

          </div>
         
         </li>

         { openUsers &&  <div className='bg-sky-100 pl-7'>
         <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/usersAccount'> Users Account </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/usersFundManagement'>  Users Fund Management </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/usersAccessManagement'>  Users Access Management  </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/usersExport'>  Export Users Email  </Link> </li>
         </div>}
        {/* <li className='flex gap-4 ' ><Link className='flex gap-3'  href='investments'>  <SiWebmoney style={{color:'rgb(58, 135, 224)',fontSize:28}} /> Portfolios  </Link> </li> */}
       

        <li className='flex gap-4 ' >
          <div onClick={()=>setOpenWithdrawal(!openWithdrawal)} className='flex justify-between items-center'>

          <div className='flex gap-3 items-center'  > 
        <BiMoneyWithdraw  style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Withdrawal   </div>
        {!openWithdrawal ? <BiSolidRightArrow /> : <BiSolidDownArrow />}

          </div>
         
         </li>

         { openWithdrawal &&  <div className='bg-sky-100 pl-7'>
         <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/approveWithdrawal'> Approve Withdrawals </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/withdrawalHistory'>  Withdrawal History  </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/withdrawalMethod'>  Withdrawal Method  </Link> </li>
         </div>}


         <li className='flex gap-4 ' >
          <div onClick={()=>setOpenInvestments(!openInvestments)} className='flex justify-between items-center'>

          <div className='flex gap-3 items-center'  > 
        < BiMoneyWithdraw   style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Investments   </div>
        {!openInvestments ? <BiSolidRightArrow /> : <BiSolidDownArrow />}

          </div>
         
         </li>

         { openInvestments &&  <div className='bg-sky-100 pl-7'>
         <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/manageActiveInvestments'> Manage Active Investments </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/investmentPackages'>  Investment Packages  </Link> </li>
         </div>}

         {/* new */}

         <li className='flex gap-4 ' >
          <div onClick={()=>setOpenPayments(!openPayments)} className='flex justify-between items-center'>

          <div className='flex gap-3 items-center'  > 
        <BsFingerprint  style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Payments   </div>
        {!openPayments ? <BiSolidRightArrow /> : <BiSolidDownArrow />}

          </div>
         
         </li>

         { openPayments &&  <div className='bg-sky-100 pl-7'>
         <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/approvePayment'> Approve Payments </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/paymentHistory'>  Payment History  </Link> </li>
         </div>}

         {/* end */}

        <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/createTransactions'>  <BsFillBoxFill style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Create Portfolio </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/loans'>  <BsFillBoxFill style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Loans </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/messages'>  <BsFillBoxFill style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Messages </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/referralCommissions'>  <BiSolidNetworkChart style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Referral Commisions </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/sendEmails'>  <BsFillSendFill  style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Send Emails  </Link> </li>

        {/* <li className='flex gap-4 ' >
          <div onClick={()=>setOpenReferral(!openReferral)} className='flex justify-between items-center'>

          <div className='flex gap-3 items-center'  > 
        <BiSolidNetworkChart  style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Referrals   </div>
        {!openReferral ? <BiSolidRightArrow /> : <BiSolidDownArrow />}

          </div>
         
         </li>

         { openReferral &&  <div className='bg-sky-100 pl-7'>
         <li className='flex gap-4 ' ><Link className='flex gap-3'  href='referal'>  All Referrals   </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  href='referralBonus'>  Referral Bonus  </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  href='referal'>  Referral Code  </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  href='referal'>  Referral Link  </Link> </li>
         </div>}

        <li className='flex gap-4 ' >
          <div onClick={()=>setOpenAuth(!openAuth)} className='flex justify-between items-center'>

          <div className='flex gap-3 items-center'  > 
        <BsFingerprint style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Authentication   </div>
        {!openAuth ? <BiSolidRightArrow /> : <BiSolidDownArrow />}

          </div>
         
         </li>
       { openAuth &&  <div className='bg-sky-100 pl-7'>
         <li className='flex gap-4 ' ><Link className='flex gap-3'  href='twoFA'>  2FA   </Link> </li>
        <li className='flex gap-4 ' ><Link className='flex gap-3'  href='updatePassword'>  Update Password  </Link> </li>
         </div>} */}

        <li className='flex gap-4 ' ><Link className='flex gap-3'  to='/twoFA'> <AiFillSetting style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Log out  </Link> </li>
      
        
      </ul>
    </div>
  </div>
  )
}

export default AdminSidebar

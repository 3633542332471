import React,{useState, useEffect} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styles from './usersAccountColumn.module.css'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector, useDispatch } from 'react-redux'
import {  selectFetchedUsers } from '../slices/userSlice'
import Loader from './Loader'

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Investors Name', width: 120 },
    { field: 'email', headerName: 'Email', width: 100 },
    { field: 'comment', headerName: 'Activity', width: 190 },
    // { field: 'investmentPackage', headerName: 'Investment Package', width: 100 },
    { field: 'date', headerName: 'Date', width: 190 },
    { field: 'amount', headerName: 'Amount', width: 100 },
    //   {
    //   field: "status",
    //   headerName: "Status",
    //   width: 160,
    //   renderCell: (params) => {
    //     return (
    //       <div style={{borderRadius:'10px'}} className='bg-green-600 p-2 text-white'>
    //         {params.row.status}
    //       </div>
    //     );
    //   },
    // },
  ];

  

const ActivitiesColumn = () => {
    const userInfo = useSelector(selectUserInfo)
    const fetchedUsers = useSelector(selectFetchedUsers)
    const dispatch = useDispatch()
    const [allResponses, setAllResponses] = useState([]);
    const [loading, setLoading] = useState(false)
  
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
  
    useEffect(() => {
      const fetchUsers =async()=>{
  
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRpYmlvIGFkbWluIiwidXNlcklkIjoiNjU2MGUxYTJkODkxZmQ0M2M5NjIwMzBhIiwiZW1haWwiOiJ0aWJpb2FkbWluQG1haWwuY29tIiwiaWF0IjoxNzAyMjIzMDY4fQ.fZBBVrOfGiMP6IEtQSClTEvC9mpmEhONNL1dWQYH4HM");
  
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        setLoading(true)
        fetch(`${API_ENDPOINT}/api/response`, requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result.userResponses)
        //   const sortedData = result.withdrawals.sort((b, a) => a.createdAt - b.createdAt)
          const dataWithDateObjects = result.userResponses.map(item => ({
            ...item,
            createdAt: new Date(item.createdAt),
          }));
          
          // Sort the array based on the createdAt property in descending order (from latest to oldest)
          const sortedData =  dataWithDateObjects.sort((a, b) => b.createdAt - a.createdAt);
          
            setAllResponses(sortedData)
            console.log(result)
            setLoading(false)
          })
          .catch(error => console.log('error', error));
            }
  
      fetchUsers()
    
   
    }, [])

    console.log(allResponses)
    // Create a new array by mapping through the transactions array
  const newData = allResponses.map((transaction) => {

    // Find the corresponding user by matching IDs
    const user = fetchedUsers.find((user) => user._id === transaction.userId);
  
   
    return {
      id: transaction._id,
      name: user ? user.name : '', // Use an empty string if the user is not found
      email: user ? user.email : '',
      comment: transaction.comment,
      amount: transaction?.amount,
      date: transaction.createdAt
    };
  });
  
  console.log(newData)
  
    //   const actionColumn = [{
    //       field: "action",
    //        headerName: "Action",
    //         width: 350,
    //          renderCell:(params)=>{
    //           // console.log(params.row)
    //       return (
    //           <div className="flex gap-1 pr-2">
    
    //              {/* <Link to={`/paymentRecords/${params.row.id}`}   state={{ weekProcessed: weekProcessed, week1Filtered:week1Filtered, weekData:weekData }}   style={{textDecoration: 'none'}}> */}
                  
    //              <div style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-sky-200 hover:bg-sky-600">Adjust Portfolio</div>
    //              <div style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-red-200 hover:bg-red-600">Delete Portfolio</div>
    //              {/* <div style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-sky-200 hover:bg-sky-600">Assign Referal Upline</div> */}
    //              {/* </Link> */}
                
    //           </div>
    //       )
    //   }}];
  
  
    return (
      <div className={styles.datatable}>
      <h1 className="m-4 text-2xl">
         Account Activities
      </h1> 
      {newData.length !== 0  ? <DataGrid
        className={styles.datagrid}
        // getRowId={(row) => row._id}
        rows={newData} columns={columns}
      //   columns={requestColumns.concat(actionColumn)}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        GridLinesVisibility="Vertical"
      /> : null}
      {/* {loading ? <h1>Loading...</h1> : null} */}
    </div>
  )
}

export default ActivitiesColumn

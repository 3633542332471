import React, { useEffect, useState } from 'react'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector} from 'react-redux'
import { selectFetchedUsers } from '../slices/userSlice'

const LoanComponent = () => {

    const userInfo = useSelector(selectUserInfo)
    const [loading, setLoading] = useState(false)
    const [loanData, setLoanData] = useState([])
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
    const fetchedUsers = useSelector(selectFetchedUsers)

    useEffect(() => {
      const fetchUsers =async()=>{
  
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkNyb3duIEFkbWluIiwidXNlcklkIjoiNjVjNjZlZDIxMGYzZTE0YTQ1YTliYWEzIiwiZW1haWwiOiJhbmR5aGF5bGVAbWFpbC5jb20iLCJpYXQiOjE3MDc1MDMzMTR9.a_0wxQhQw21tV7M1zYbQAyvuy_OeuycnOZGIl9qIU1k");
  
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        setLoading(true)
        fetch(`${API_ENDPOINT}/api/loan`, requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result.loans)
            const dataWithSerializedDates = result.loans.map(item => {
              const createdAt = item.createdAt instanceof Date ? item.createdAt : new Date(item.createdAt);
              return {
                ...item,
                createdAt: createdAt.toISOString(),
              };
            });
                // Sort the array based on the createdAt property in descending order (from latest to oldest)
                const sortedData =  dataWithSerializedDates.sort((a, b) => b.createdAt - a.createdAt);
            setLoanData(sortedData)
            console.log(sortedData)
            console.log(result)
            setLoading(false)
          })
          .catch(error => console.log('error', error));
            }
  
      fetchUsers()
    
      // return () => {
      //   second
      // }
    }, [])

  return (
    <div style={{width:'80%', margin:'100px auto'}} className='bg-green-50 p-2'>
      {
  loanData.map((data, index) => {
    // Filter users based on user._id
    const filteredUsers = fetchedUsers.filter((user) => user._id === data.userId);

    // Assuming each user has a 'name' property
    const userName = filteredUsers.length > 0 ? filteredUsers[0].name : "Unknown";

    return (
      <div key={data._id} className='bg-green-100 m-2 p-2'>
        <h1 className='text-xl text-green-800'>Certificate ID: {data.certificateID}</h1>
        <div className='flex p-2 items-center justify-between'>
          <p className='text-2xl font-bold'>{userName}</p>
          <p>${data.amount}</p>
        </div>
        <p className='text-sm font-bold text-right'>Date Issued: {data.dateIssued}</p>
      </div>
    );
  })
}
    </div>
  )
}

export default LoanComponent

import AdminNavbar from '../components/AdminNavbar'
import AdminSidebar from '../components/AdminSidebar'
import PaymentHistoryColumn from '../components/PaymentHistoryColumn'
import React from 'react'

const page = () => {
  return (
    <div>
      <AdminNavbar />
      <AdminSidebar />
      <PaymentHistoryColumn />
    </div>
  )
}

export default page

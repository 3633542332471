import AdminNavbar from '../components/AdminNavbar'
import AdminSidebar from '../components/AdminSidebar'
import AdminWithdrawalHistoryColumn from '../components/AdminWithdrawalHistoryColumn'
import React from 'react'

const page = () => {
  return (
    <div>
      <AdminNavbar />
      <AdminSidebar />
      <AdminWithdrawalHistoryColumn />
    </div>
  )
}

export default page

import AdminNavbar from '../components/AdminNavbar'
import AdminSidebar from '../components/AdminSidebar'
import ApproveWithdrawalColumn from '../components/ApproveWithdrawalColumn'
import React from 'react'

const page = () => {
  return (
    <div>
      <AdminNavbar />
      <AdminSidebar />
      <ApproveWithdrawalColumn />
    </div>
  )
}

export default page

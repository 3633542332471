import React from 'react'
import AdminNavbar from '../components/AdminNavbar'
import AdminSidebar from '../components/AdminSidebar'
import MessagesComponent from '../components/MessagesComponent'

const Messages = () => {
  return (
    <div>
         <AdminNavbar />
         <AdminSidebar />
         <MessagesComponent />
    </div>
  )
}

export default Messages

import React,{useState, useEffect} from 'react'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector, useDispatch } from 'react-redux'
import { setFetchedUsers } from '../slices/userSlice'
import Loader from './Loader'

const MessagesComponent = () => {

    const userInfo = useSelector(selectUserInfo)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [allMessages, setAllMessages] = useState([])

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
  
    useEffect(() => {
      const fetchAllMessages =async()=>{
  
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRpYmlvIGFkbWluIiwidXNlcklkIjoiNjU2MGUxYTJkODkxZmQ0M2M5NjIwMzBhIiwiZW1haWwiOiJ0aWJpb2FkbWluQG1haWwuY29tIiwiaWF0IjoxNzAyMjIzMDY4fQ.fZBBVrOfGiMP6IEtQSClTEvC9mpmEhONNL1dWQYH4HM");
  
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        setLoading(true)
        fetch(`${API_ENDPOINT}/api/contactMessage`, requestOptions)
          .then(response => response.json())
          .then(result => {
  
            const dataWithSerializedDates = result.contactMessages.map(item => {
              const createdAt = item.createdAt instanceof Date ? item.createdAt : new Date(item.createdAt);
              return {
                ...item,
                createdAt: createdAt.toISOString(),
              };
            });
                // Sort the array based on the createdAt property in descending order (from latest to oldest)
                const sortedData =  dataWithSerializedDates.sort((a, b) => b.createdAt - a.createdAt);
  
            setAllMessages(sortedData)
            console.log(result)
            setLoading(false)
          })
          .catch(error => console.log('error', error));
            }
  
            fetchAllMessages()
    
      // return () => {
      //   second
      // }
    }, [])

  return (
    <div className='flex flex-col m-7 p-7 gap-5'>
     {allMessages.map((message)=>(
        <div key={message._id} className='p-5 shadow-lg rounded-sm'>
            <p className='font-bold text-sm'>{message.email}</p>
            <p className='text-xl p-3 bg-sky-50 '>{message.message}</p>
            <p style={{textAlign:'right'}} className='font-bold text-sm text-gray-300'>{new Date(message.createdAt).toDateString()}</p>
        </div>
     ))}
    </div>
  )
}

export default MessagesComponent

import Login from '../components/Login'


export default function Home() {
  return (
    <main className="">
      <Login />
    </main>
  )
}

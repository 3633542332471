import React from 'react'

const ReferralCommisionsColumn = () => {
  return (
    <div style={{width:'80%', margin:'auto'}} className='m-8'>
      <h1 className='text-2xl font-bold m-4'>Referral Commision</h1>

      <form action="" className='bg-sky-100 p-8 flex flex-col'>
        <label className='mt-1' htmlFor="userAccount">Downline Account</label>
        <input style={{height:40, borderRadius:5}} className='bg-white mt-1' type="text" />
        <label className='mt-3' htmlFor="userAccount">Amount</label>
        <input style={{height:40, borderRadius:5}} className='bg-white mt-1' type="text" />
        <button style={{borderRadius:10}} className='bg-sky-800 text-white p-4 mt-5'>Add Commission to 3 Levels</button>
      </form>
      
    </div>
  )
}

export default ReferralCommisionsColumn

import React,{useEffect, useState} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styles from './usersAccountColumn.module.css'
import { selectFetchedUsers } from '../slices/userSlice';
import { useSelector } from 'react-redux'

const columns = [
    { field: '_id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Fullname', width: 120 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'phone', headerName: 'Phone Number', width: 160 },
    { field: 'reffered_by', headerName: 'Referred By', width: 160 },
    { field: 'referral_code', headerName: 'Referral ID', width: 160 },
    { field: 'mainWallet', headerName: 'Main Wallet', width: 160 },
    { field: 'dailyWalletTotal', headerName: 'Daily Wallet', width: 160 },
    { field: 'referralCommissionWalletTotal', headerName: 'Referral Comm. Wallet', width: 160 },
    { field: 'downlineCommissionWalletTotal', headerName: 'Downline Comm. Wallet', width: 160 },
    { field: 'bonusWalletTotal', headerName: 'Bonus Wallet', width: 160 },
    //   {
    //   field: "status",
    //   headerName: "Status",
    //   width: 160,
    //   renderCell: (params) => {
    //     return (
    //       <div style={{borderRadius:'10px'}} className='bg-green-600 p-2 text-white'>
    //         {params.row.status}
    //       </div>
    //     );
    //   },
    // },
  ];
  const rows = [
    { id: 1, fullName: 'Mike Ejiagha', email: 'mm@m.co', phoneNumber:'902833445', referredBy: 'Jane Doe', referralID: '87', wallet: '$7876',  status:'Registration'},
    { id: 2, fullName: 'Joe Ejiagha ', email: 'hghg@hj.co', phoneNumber:'90283344500', referredBy: 'Jane Doe', referralID: '87', wallet: '$7876', status:'Funding' },
    { id: 3, fullName: 'Lord Bedtner ', email: 'hggnj@hhhj.co', phoneNumber:'9028334450', referredBy: 'Jane Doe', referralID: '87', wallet: '$7876', status:'Withdrawal' },
    { id: 4, fullName: 'Donal Trump ', email: 'jhjh@jhhhj.co', phoneNumber:'9028334450', referredBy: 'Jane Doe', referralID: '87', wallet: '$7876',  status:'Transfer' },
    // Add more data rows as needed
  ];
  // type MyDataGridProps = {
  //   pageSize: number;
  //   rowsPerPageOptions: number[];
  // };


const UsersAccountColumn = () => {
  const [isLoading, setIsLoading] = useState(false);
    const fetchedUsers = useSelector(selectFetchedUsers)

    
    console.log(fetchedUsers)
  
  return (
    <div className={styles.datatable}>
    <h1 className="m-4 text-2xl">
       USERS
    </h1>
    <DataGrid
      className={styles.datagrid}
      getRowId={(row) => row._id}
      rows={fetchedUsers} columns={columns}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      GridLinesVisibility="Vertical"
    />
    {/* {loading ? <h1>Loading...</h1> : null} */}
  </div>
  )
}

export default UsersAccountColumn


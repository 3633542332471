import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styles from './usersAccountColumn.module.css'
import { useSelector, useDispatch } from 'react-redux'
import { selectFetchedUsers } from '../slices/userSlice'
import Loader from './Loader'
import { FaRegTimesCircle } from 'react-icons/fa'

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Fullname', width: 120 },
    { field: 'email', headerName: 'Email', width: 100 },
    { field: 'phone', headerName: 'Phone Number', width: 100 },
    { field: 'referredBy', headerName: 'Referred By', width: 100 },
    { field: 'mainWallet', headerName: 'Wallet', width: 100 },
    { field: 'dailyWalletTotal', headerName: 'Daily Wallet', width: 100 },
    { field: 'compoundingWalletTotal', headerName: 'Compounding Wallet', width: 100 },
      {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div style={{width:100,borderRadius:'10px', backgroundColor: params?.row?.status === true ? 'green' : 'goldenrod'}} className=' p-2 text-white text-center'>
            {params?.row?.status === true ? 'Verified' : 'Unverified'}
          </div>
        );
      },
    },
  ];
  const rows = [
    { id: 1, fullName: 'Mike Ejiagha', email: 'mm@m.co', phoneNumber:'902833445', referredBy: 'Jane Doe', referralID: '87', wallet: '$7876',  status:'verified'},
    { id: 2, fullName: 'Joe Ejiagha ', email: 'hghg@hj.co', phoneNumber:'90283344500', referredBy: 'Jane Doe', referralID: '87', wallet: '$7876', status:'verified' },
    { id: 3, fullName: 'Lord Bedtner ', email: 'hggnj@hhhj.co', phoneNumber:'9028334450', referredBy: 'Jane Doe', referralID: '87', wallet: '$7876', status:'verified' },
    { id: 4, fullName: 'Donal Trump ', email: 'jhjh@jhhhj.co', phoneNumber:'9028334450', referredBy: 'Jane Doe', referralID: '87', wallet: '$7876',  status:'verified' },
    // Add more data rows as needed
  ];
  // type MyDataGridProps = {
  //   pageSize: number;
  //   rowsPerPageOptions: number[];
  // };


const UsersAccessManagementColumn = () => {

  const fetchedUsers = useSelector(selectFetchedUsers)
  const [showModal, setShowModal] = useState(false)
  const [popUp, setPopUp] = useState('')
  const [showPopupModal, setShowPopupModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
  const [clickedUser, setClickedUser] = useState([])

  const newData = fetchedUsers.map(user => {
    // console.log(`Processing user: ${user.name}, reffered_by: ${user.reffered_by}`);
    const referredByDetails = fetchedUsers.find(referred => {
      // console.log(`Checking referred user: ${referred.name}, _id: ${referred._id}`);
      return referred._id == user.reffered_by;
    });
  
    // console.log(`Referred by details: ${referredByDetails}`);
     
    return {
      id: user._id,
      email: user.email,
      name: user.name,
      phone: user.phone,
      mainWallet: user.mainWallet,
      bonusWalletTotal: user.bonusWalletTotal,
      downlineCommissionWalletTotal: user.downlineCommissionWalletTotal,
      referralCommissionWalletTotal: user.referralCommissionWalletTotal,
      dailyWalletTotal: user.dailyWalletTotal,
      compoundingWalletTotal: user.compoundingWalletTotal,
      status: user.has_activated,
      referredBy: referredByDetails ? referredByDetails.email : null
    };
  });



  const showEditWallet=async(id)=>{
    setShowModal(true)

    const filterClickedUser = newData.filter(user=> user.id == id)
    setClickedUser(filterClickedUser)
    console.log(filterClickedUser)
  }

  const [mainWallet, setMainWallet] = useState('')
  const [bonusWallet, setBonusWallet] = useState('')
  const [downlineWallet, setDownlineWallet] = useState('')
  const [referralWallet, setReferralWallet] = useState('')


  const handleWalletUpdate =async(e)=>{
    e.preventDefault()
    if(mainWallet === null){
      setPopUp('Enter The amount you want to update')
      setShowPopupModal(true)
    } 

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkNyb3duIEFkbWluIiwidXNlcklkIjoiNjVjNjZlZDIxMGYzZTE0YTQ1YTliYWEzIiwiZW1haWwiOiJhbmR5aGF5bGVAbWFpbC5jb20iLCJpYXQiOjE3MDc1MDMzMTR9.a_0wxQhQw21tV7M1zYbQAyvuy_OeuycnOZGIl9qIU1k");


    var formdata = new FormData();
    if(mainWallet){
      formdata.append("mainWallet", mainWallet);
    }
    if(bonusWallet){
      formdata.append("bonusWalletTotal", bonusWallet);
    }
    if(downlineWallet){
      formdata.append("downlineCommissionWalletTotal", downlineWallet);
    }
    if(referralWallet){
      formdata.append("referralCommissionWalletTotal", referralWallet);
    }

  
  
console.log(clickedUser[0].id)
var requestOptions = {
  method: 'PATCH',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

console.log(mainWallet)

setLoading(true)

fetch(`${API_ENDPOINT}/api/users/updateUser/${clickedUser[0].id}`, requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if(result.message=== 'Profile Updated Successfully'){
      // dispatch(setUserInfo(result))
      //  navigate('/dashboard')
      setPopUp('User Wallet Updated Successfully')
      setShowPopupModal(true)
    
      setLoading(false)
       console.log(result)
    } else {
      setPopUp('Error Updating main wallet, try again later')
      setShowPopupModal(true)
      const form = e.target;
      form.reset()
      setLoading(false)
    }
    // console.log(result)
  })
  .catch(error => console.log('error', error));
  }

  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
    }
  


    const actionColumn = [{
        field: "action",
         headerName: "Action",
          width: 600,
           renderCell:(params)=>{
            // console.log(params.row)
        return (
            <div className="flex gap-1 pr-2">
  
               {/* <Link to={`/paymentRecords/${params.row.id}`}   state={{ weekProcessed: weekProcessed, week1Filtered:week1Filtered, weekData:weekData }}   style={{textDecoration: 'none'}}> */}
                
               <div onClick={()=>showEditWallet(params.id)} style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-sky-200 hover:bg-sky-600">Edit Wallet</div>
               <div  style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-sky-200 hover:bg-sky-600">Edit Daily Wallet</div>
               <div  style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-sky-200 hover:bg-sky-600">Edit Compounding Wallet</div>
               {/* <div onClick={()=>showEditWallet(params.id)} style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-sky-200 hover:bg-sky-600">Edit Referral Wallet</div> */}
               {/* <div onClick={()=>showEditWallet(params.id)} style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-sky-200 hover:bg-sky-600">Edit Downline Comm. Wallet</div> */}
               {/* <div onClick={()=>showEditWallet(params.id)} style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-sky-200 hover:bg-sky-600">Edit Bonus Wallet</div> */}
               <div style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-red-200 hover:bg-red-600">Stop Earning</div>
               {/* <div style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-sky-200 hover:bg-sky-600">Assign Referal Upline</div> */}
               {/* </Link> */}
              
            </div>
        )
    }}];

  return (
    <div className={styles.datatable}>
            {loading ? <Loader /> : null}
    {/* popup */}
    { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}
    <h1 className="m-4 text-2xl">
       USERS
    </h1>
    <DataGrid
      className={styles.datagrid}
      // getRowId={(row) => row._id}
      rows={newData} columns={columns.concat(actionColumn)}
    //   columns={requestColumns.concat(actionColumn)}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      GridLinesVisibility="Vertical"
    />
   
   { showModal &&  <div className={styles.modal}>
     < FaRegTimesCircle onClick={()=>setShowModal(false)} className={styles.close} />
      <div  className={styles.modalInnerContainer}>
       
        <form onSubmit={handleWalletUpdate} style={{width:'300px'}} className='flex flex-col gap-3 p-7'>
          <h1 className='text-4xl font-bold pb-6 pt-2 text-center'>Update Wallet</h1>
          <label className='font-bold' htmlFor="email">Email</label>
          <input 
          // onFocus={()=>setClearInput('')}
         
          disabled
          placeholder={clickedUser[0]?.email}
           style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10, marginTop:-10}} 
          type="email" name='email' />

          <label className='font-bold' htmlFor="mainWallet">Main Wallet</label>
          <input 
          onChange={(e)=>setMainWallet(e.target.value)}
          placeholder={clickedUser[0]?.mainWallet}
           style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10,marginTop:-10}} 
          type="number" name='mainWallet' />

          <label className='font-bold' htmlFor="mainWallet">Bonus Wallet</label>
          <input 
          onChange={(e)=>setBonusWallet(e.target.value)}
          placeholder={clickedUser[0]?.bonusWalletTotal}
           style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10,marginTop:-10}} 
          type="number" name='mainWallet' />

          <label className='font-bold' htmlFor="mainWallet">Downline Comm. Wallet</label>
          <input 
          onChange={(e)=>setDownlineWallet(e.target.value)}
          placeholder={clickedUser[0]?.downlineCommissionWalletTotal}
           style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10,marginTop:-10}} 
          type="number" name='mainWallet' />

          <label className='font-bold' htmlFor="mainWallet">Referral Wallet</label>
          <input 
          onChange={(e)=>setReferralWallet(e.target.value)}
          placeholder={clickedUser[0]?.referralCommissionWalletTotal}
           style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10,marginTop:-10}} 
          type="number" name='mainWallet' />

          <div style={{width:'100%'}}>
          <button className={styles.loginButton} type="submit">Update</button>
          </div>
       
        {/* <Link href='signup' className='text-sm'>Dont have an account? <strong className=''>Sign up</strong></Link> */}
        </form>
      </div>

      </div> }
  </div>
  )
}

export default UsersAccessManagementColumn


import React from 'react'

const SendEmailColumn = () => {
  return (
    <div style={{width:'80%', margin:'auto'}} className='m-8'>
    <h1 className='text-2xl font-bold m-4'>Emails</h1>

    <form action="" className='bg-sky-100 p-8 flex flex-col'>
      <label className='mt-1' htmlFor="userAccount">Select User</label>
      <input style={{height:40, borderRadius:5}} className='bg-white mt-1' type="text" />
      <label className='mt-3' htmlFor="userAccount">Select Email</label>
      <input style={{height:40, borderRadius:5}} className='bg-white mt-1' type="text" />
      <label className='mt-3' htmlFor="userAccount">Subject</label>
      <input style={{height:40, borderRadius:5}} className='bg-white mt-1' type="text" />
      <label className='mt-3' htmlFor="userAccount">Enter Message</label>
      <input style={{height:100, borderRadius:5}} className='bg-white mt-1' type="text" />
      <button style={{borderRadius:10}} className='bg-sky-800 text-white p-4 mt-5'>Send Email</button>
    </form>
    
  </div>
  )
}

export default SendEmailColumn

import React from 'react'
import AdminNavbar from '../components/AdminNavbar'
import AdminSidebar from '../components/AdminSidebar'
import LoanComponent from '../components/LoanComponent'

const Loans = () => {
  return (
    <div>
      <AdminNavbar />
      <AdminSidebar />
      <LoanComponent />
    </div>
  )
}

export default Loans

import React, { useState } from 'react'
import { FaRegTimesCircle } from 'react-icons/fa'
import styles from './createTransaction.module.css'
import Loader from './Loader'

const CreateTransactionsColumn = () => {

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'

  const [loading, setLoading] = useState(false)
  const [popUp, setPopUp] = useState('')
  const [showPopupModal, setShowPopupModal] = useState(false)

  const [email, setEmail] = useState('')
  const [duration, setDuration] = useState('')
  const [percentage, setPercentage] = useState('')
  const [amount, setAmount] = useState('')
  const [portfolioType, setPortfolioType] = useState('')
  const [dailyProfit, setDailyProfit] = useState('')
  const [compoundingProfit, setCompoundingProfit] = useState('')

  const createPortfolio = async(e)=>{
    e.preventDefault()

    if(!amount || !email || !portfolioType || !dailyProfit || !compoundingProfit || !percentage){
      setPopUp('Fill in all the details to create a portfolio')
      setShowPopupModal(true)
      setLoading(false)
     return
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkNyb3duIEFkbWluIiwidXNlcklkIjoiNjVjNjZlZDIxMGYzZTE0YTQ1YTliYWEzIiwiZW1haWwiOiJhbmR5aGF5bGVAbWFpbC5jb20iLCJpYXQiOjE3MDc1MDMzMTR9.a_0wxQhQw21tV7M1zYbQAyvuy_OeuycnOZGIl9qIU1k");


var formdata = new FormData();
formdata.append("amount", amount);
formdata.append("email", email);
formdata.append("duration", 9);
formdata.append("type", portfolioType);
formdata.append("dailyProfit", dailyProfit);
formdata.append("compoundingProfit", compoundingProfit);
formdata.append("percentage", percentage);


var requestOptions = {
method: 'PUT',
headers: myHeaders,
body: formdata,
redirect: 'follow'
};

setLoading(true)

fetch(`${API_ENDPOINT}/api/portfolios/createAdmin`, requestOptions)
.then(response => response.json())
.then(result => {
  console.log(result)
if(result.message === 'Portfolio creation started successfully'){
    
     const form = e.target;
     form.reset()

  setPopUp('Portfolio created successfully')
  setShowPopupModal(true)
  setLoading(false)
  return
} else {
  setPopUp('Error creating portfolio, try again')
  setShowPopupModal(true)
  setLoading(false)
  return
}

})
.catch(error => console.log('error', error));

}


const handleClosePopup =()=>{
  setShowPopupModal(false)
   setPopUp('')
  }


  return (
    <div style={{width:'80%', margin:'auto'}} className='m-8'>
       {loading ? <Loader /> : null}
             {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}

      <h1 className='text-2xl font-bold m-4'>Create Portfolio</h1>

      <form onSubmit={createPortfolio} className='bg-green-100 p-8 flex flex-col'>
        <label className='mt-1' htmlFor="userAccount">User Email Account</label>
        <input
         onChange={(e)=>{setEmail(e.target.value)}}
         placeholder='Enter Email'
         style={{height:40, borderRadius:5}} className='bg-white mt-1 pl-1' type="email" />
         <label className='mt-3' htmlFor="userAccount">Amount</label>
        <input
         onChange={(e)=>{setAmount(e.target.value)}}
         placeholder='amount'
        style={{height:40, borderRadius:5}} className='bg-white mt-1 pl-1' type="number" />
        <label className='mt-3' htmlFor="userAccount">Percentage</label>
        <input
         onChange={(e)=>{setPercentage(e.target.value)}}
         placeholder='should be same with type percentage'
         style={{height:40, borderRadius:5}} className='bg-white mt-1 pl-1' type="text" />
        <label className='mt-3' htmlFor="userAccount">Portfolio Type</label>
        <input
         onChange={(e)=>{setPortfolioType(e.target.value)}}
          placeholder='Either daily or compounding'
        style={{height:40, borderRadius:5}} className='bg-white mt-1 pl-1' type="text" />
        <label className='mt-3' htmlFor="userAccount">Daily Profit</label>
        <input 
                 onChange={(e)=>{setDailyProfit(e.target.value)}}
                 placeholder='dailyProfit percentage'
        style={{height:40, borderRadius:5}} className='bg-white mt-1 pl-1' type="text" />
        <label className='mt-3' htmlFor="userAccount">Compounding Profit</label>
        <input 
                   onChange={(e)=>{setCompoundingProfit(e.target.value)}}
                   placeholder='compoundingProfit percentage'
        style={{height:40, borderRadius:5}} className='bg-white mt-1 pl-1' type="text" />
        <button style={{borderRadius:10}} className={styles.loginButton}>Create Portfolio</button>
      </form>
    </div>
  )
}

export default CreateTransactionsColumn

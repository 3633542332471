import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styles from './usersAccountColumn.module.css'

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'fullName', headerName: 'FullName', width: 120 },
    { field: 'portfolio', headerName: 'Portfolio', width: 100 },
    { field: 'currency', headerName: 'Currency', width: 100 },
    { field: 'date', headerName: 'Date', width: 100 },
    { field: 'amount', headerName: 'Amount', width: 100 },
    { field: 'payoutMethod', headerName: 'Payout Method', width: 100 },
    { field: 'roi', headerName: 'Daily ROI', width: 100 },
    { field: 'walletAddress', headerName: 'Wallet Address', width: 100 },
    { field: 'typeOfPayment', headerName: 'Type Of Payment', width: 100 },
      {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div style={{borderRadius:'10px'}} className='bg-green-600 p-2 text-white'>
            {params.row.status}
          </div>
        );
      },
    },
  ];
  const rows = [
    { id: 1, fullName: 'Mike Ejiagha', currency: 'BTC',date: '22 september 2023 10:12:00AM', payoutMethod: 'Daily Payout', portfolio:'Green Bond', typeOfPayment: 'Fresh Investment', amount: '87', walletAddress: 'sregerf7876', roi:'$3.75',   status:'pending'},
    { id: 2, fullName: 'Joe Ejiagha ', currency: 'BTC',date: '22 september 2023 10:12:00AM' ,payoutMethod: 'Daily Payout', portfolio:'Green Bond', typeOfPayment: 'Fresh Investment', amount: '87', walletAddress: 'rgddge457876', roi:'$3.75',  status:'pending' },
    { id: 3, fullName: 'Lord Bedtner ', currency: 'BTC',date: '22 september 2023 10:12:00AM', payoutMethod: 'Daily Payout', portfolio:'Green Bond', typeOfPayment: 'Fresh Investment', amount: '87', walletAddress: 'dfsdfsgd7876', roi:'$3.75',  status:'pending' },
    { id: 4, fullName: 'Donal Trump ', currency: 'BTC',date: '22 september 2023 10:12:00AM', payoutMethod: 'Daily Payout', portfolio:'Green Bond', typeOfPayment: 'Fresh Investment', amount: '87', walletAddress: 'fdsddfdt56ytg7876', roi:'$3.75',   status:'pending' },
    // Add more data rows as needed
  ];
  // type MyDataGridProps = {
  //   pageSize: number;
  //   rowsPerPageOptions: number[];
  // };

const PaymentHistoryColumn = () => {
  return (
    <div className={styles.datatable}>
    <h1 className="m-4 text-2xl">
       Payments
    </h1>
    <DataGrid
      className={styles.datagrid}
      rows={rows} columns={columns}
    //   columns={requestColumns.concat(actionColumn)}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      GridLinesVisibility="Vertical"
    />
    {/* {loading ? <h1>Loading...</h1> : null} */}
  </div>
  )
}

export default PaymentHistoryColumn

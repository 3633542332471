import React,{useState, useEffect} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styles from './usersAccountColumn.module.css'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector, useDispatch } from 'react-redux'
import {  selectFetchedUsers } from '../slices/userSlice'
import Loader from './Loader'

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Fullname', width: 120 },
    { field: 'email', headerName: 'Email', width: 100 },
    { field: 'walletType', headerName: 'Wallet Type', width: 100 },
    { field: 'amount', headerName: 'Amount Paid', width: 100 },
    { field: 'charge', headerName: 'Charge', width: 100 },
    { field: 'walletAddress', headerName: 'Wallet Address', width: 100 },
    // { field: 'amountCredited', headerName: 'Amount Credited', width: 100 },
    // { field: 'transactionToken', headerName: 'Transaction Token', width: 100 },
      {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div style={{borderRadius:'10px', backgroundColor: `${params.row.status === 'approved' ? 'green' :params.row.status === 'declined' ? 'red' : 'skyblue'}`}} className='p-2 text-white'>
            {params.row.status}
          </div>
        );
      },
    },
  ];
  // const rows = [
  //   { id: 1, fullName: 'Mike Ejiagha', email: 'mm@m.co', withdrawalMethod:'BTC', walletAddress: 'Jjhjhwd6654623dhg76656e', amount: '$687', transactionToken: 'Main_wallet',charge: '$5', amountCredited: '$565',  status:'approved'},
  //   { id: 2, fullName: 'Joe Ejiagha ', email: 'hghg@hj.co', withdrawalMethod:'BTC', walletAddress: 'Jjhjhwd6654623dhg76656e', amount: '$687', transactionToken: 'Main_wallet',charge: '$5', amountCredited: '$565', status:'not approved' },
  //   { id: 3, fullName: 'Lord Bedtner ', email: 'hggnj@hhhj.co', withdrawalMethod:'BTC', walletAddress: 'Jjhjhwd6654623dhg76656e', amount: '$687', transactionToken: 'Main_wallet',charge: '$5', amountCredited: '$565', status:'approved' },
  //   { id: 4, fullName: 'Donal Trump ', email: 'jhjh@jhhhj.co', withdrawalMethod:'BTC', walletAddress: 'Jjhjhwd6654623dhg76656e', amount: '$687', transactionToken: 'Main_wallet',charge: '$5', amountCredited: '$565',  status:'not approved' },
  
  // ];
  // type MyDataGridProps = {
  //   pageSize: number;
  //   rowsPerPageOptions: number[];
  // };

 
const AdminWithdrawalHistoryColumn = () => {

  const userInfo = useSelector(selectUserInfo)
  const fetchedUsers = useSelector(selectFetchedUsers)
  const dispatch = useDispatch()
  const [allWithdrawals, setAllWithdrawals] = useState([]);
  const [loading, setLoading] = useState(false)

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'

  useEffect(() => {
    const fetchUsers =async()=>{

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRpYmlvIGFkbWluIiwidXNlcklkIjoiNjU2MGUxYTJkODkxZmQ0M2M5NjIwMzBhIiwiZW1haWwiOiJ0aWJpb2FkbWluQG1haWwuY29tIiwiaWF0IjoxNzAyMjIzMDY4fQ.fZBBVrOfGiMP6IEtQSClTEvC9mpmEhONNL1dWQYH4HM");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      setLoading(true)
      fetch(`${API_ENDPOINT}/api/withdrawal`, requestOptions)
        .then(response => response.json())
        .then(result => {
      
        // const sortedData = result.withdrawals.sort((b, a) => a.createdAt - b.createdAt)
        const dataWithDateObjects = result.withdrawals.map(item => ({
          ...item,
          createdAt: new Date(item.createdAt),
        }));
        
        // Sort the array based on the createdAt property in descending order (from latest to oldest)
        const sortedData =  dataWithDateObjects.sort((a, b) => b.createdAt - a.createdAt);
        
          setAllWithdrawals(sortedData)
          console.log(result)
          setLoading(false)
        })
        .catch(error => console.log('error', error));
          }

    fetchUsers()
  
    // return () => {
    //   second
    // }
  }, [])
  // Create a new array by mapping through the transactions array
const newData = allWithdrawals.map((transaction) => {
  // Find the corresponding user by matching IDs
  const user = fetchedUsers.find((user) => user._id === transaction.user);

  // Combine user data and transaction data
  return {
    id: transaction._id,
    name: user ? user.name : '', // Use an empty string if the user is not found
    email: user ? user.email : '',
    walletType: transaction.walletType,
    amount: transaction.amount,
    charge: transaction.charge,
    walletAddress: transaction.walletAddress,
    // amountCredited: transaction.amountCredited,
    // transactionToken: transaction.transactionToken,
    status: transaction.status,
  };
});

    // const actionColumn = [{
    //     field: "action",
    //      headerName: "Action",
    //       width: 350,
    //        renderCell:(params)=>{
           
    //     return (
    //         <div className="flex gap-1 pr-2">
  
          
    //            <div style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-sky-200 hover:bg-sky-600">Approve</div>
    //            <div style={{borderRadius:'10px', cursor:'pointer'}} className="p-2 bg-red-200 hover:bg-red-600">Decline</div>
               
              
    //         </div>
    //     )
    // }}];

    console.log(allWithdrawals)
  return (
    <div className={styles.datatable}>
    <h1 className="m-4 text-2xl">
       Withdrawal History
    </h1>
    <DataGrid
      className={styles.datagrid}
      // getRowId={(row) => row._id}
      rows={newData} columns={columns}
    //   columns={requestColumns.concat(actionColumn)}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      GridLinesVisibility="Vertical"
    />
    {/* {loading ? <h1>Loading...</h1> : null} */}
  </div>
  )
}

export default AdminWithdrawalHistoryColumn

import React from 'react'
import AdminNavbar from '../components/AdminNavbar'
import AdminSidebar from '../components/AdminSidebar'
import ActivitiesColumn from '../components/ActivitiesColumn'

const Activities = () => {
  return (
    <div>
        <AdminNavbar />
        <AdminSidebar />
        <ActivitiesColumn />
      
    </div>
  )
}

export default Activities

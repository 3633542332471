import React,{useEffect, useState} from 'react'
import styles from './adminDashboardHome.module.css'
import { AiOutlineArrowUp } from 'react-icons/ai'
import { FaUsers } from 'react-icons/fa'
import { BsBarChartSteps } from 'react-icons/bs'
import { BsCurrencyDollar } from 'react-icons/bs'
import { BiBarChart } from 'react-icons/bi'
import { PiArrowElbowRightBold } from 'react-icons/pi'
import { selectFetchedUsers } from '../slices/userSlice';
import { useSelector } from 'react-redux'

const AdminDashboardHome = () => {
    const [isLoading, setIsLoading] = useState(false);
    const fetchedUsers = useSelector(selectFetchedUsers)
 
  return (
    <div>
    <div className={styles.container}>

      <div className={styles.left}>

        <div style={{width:'100%', borderRadius:'10px'}}  className='p-3 flex justify-between bg-green-50 shadow-md gap-8 items-center'>
            <div className='flex flex-col gap-3'>
                <h1>Total Users</h1>
                <p className='font-bold text-2xl'>{fetchedUsers.length}</p>
                <AiOutlineArrowUp style={{fontSize:'20px',borderRadius:'100%'}}  className='bg-green-700 text-white p-1' />
            </div>
            <FaUsers style={{fontSize:'50px',borderRadius:'100%'}} className='bg-green-200 text-green-800 p-2' />
        </div>

        <div style={{width:'100%', borderRadius:'10px'}}  className='p-3 flex justify-between bg-green-50 shadow-md gap-8 items-center'>
            <div className='flex flex-col gap-3'>
                <h1>Total Amount Invested</h1>
                <p className='font-bold text-2xl'>$200567</p>
                <AiOutlineArrowUp style={{fontSize:'20px',borderRadius:'100%'}}  className='bg-green-700 text-white p-1' />
            </div>
            <BsBarChartSteps style={{fontSize:'50px',borderRadius:'100%'}} className='bg-green-200 text-green-800 p-2' />
        </div>

        <div style={{width:'100%', borderRadius:'10px'}}  className='p-3 flex justify-between bg-green-50 shadow-md gap-8 items-center'>
            <div className='flex flex-col gap-3'>
                <h1>Total Amount Withdrawn</h1>
                <p className='font-bold text-2xl'>$45200</p>
                <AiOutlineArrowUp style={{fontSize:'20px',borderRadius:'100%'}}  className='bg-green-700 text-white p-1' />
            </div>
            <BsCurrencyDollar style={{fontSize:'50px',borderRadius:'100%'}} className='bg-green-200 text-green-800 p-2' />
        </div>

      </div>


      <div className={styles.right}>

      <div style={{height:'280px',width:'100%', borderRadius:'10px'}}  className='p-3 flex flex-col justify-center bg-green-50 shadow-md gap-8 items-center'>
            <div className='flex flex-col gap-3 items-center justify-center'>
                <h1>All Investment Packages</h1>
                <p className='font-bold text-2xl'>-----------</p>
                <AiOutlineArrowUp style={{fontSize:'20px',borderRadius:'100%'}}  className='bg-green-700 text-white p-1' />
            </div>
            <BsCurrencyDollar style={{fontSize:'50px',borderRadius:'100%'}} className='bg-green-200 text-green-800 p-2' />
        </div>

        <div style={{height:'280px',width:'100%', borderRadius:'10px'}}  className='p-3 flex flex-col justify-center bg-green-50 shadow-md gap-8 items-center'>
            <div className='flex flex-col gap-3 items-center justify-center'>
                <h1>All Investment Packages</h1>
                <p className='font-bold text-2xl'>-----------</p>
                <AiOutlineArrowUp style={{fontSize:'20px',borderRadius:'100%'}}  className='bg-green-700 text-white p-1' />
            </div>
            <BsCurrencyDollar style={{fontSize:'50px',borderRadius:'100%'}} className='bg-sky-200 text-green-800 p-2' />
        </div>

      </div>

    </div>


<div  className={styles.bottom}>

<div className='p-3'>
    <div className='flex gap-0 items-center'>
    <BiBarChart className='text-sky-50 text-3xl'/>
    <PiArrowElbowRightBold className='text-green-500' />
    </div>
    <h1 className='text-4xl'>8</h1>
    <p className='pt-4'>Withdrawal Method</p>
   
</div>

<div className='p-3'>
    <div className='flex gap-0 items-center'>
    <BiBarChart className='text-sky-50 text-3xl'/>
    <PiArrowElbowRightBold className='text-green-500' />
    </div>
    <h1 className='text-4xl'>8</h1>
    <p className='pt-4'>Investment Packages</p>
   
</div>

<div className='p-3'>
    <div className='flex gap-0 items-center'>
    <BiBarChart className='text-sky-50 text-3xl'/>
    <PiArrowElbowRightBold className='text-green-500' />
    </div>
    <h1 className='text-4xl'>8</h1>
    <p className='pt-4'>Withdrawals</p>
   
</div>

</div>

</div>
  )
}

export default AdminDashboardHome
